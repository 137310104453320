import { graphql } from 'gatsby';
import styled from 'styled-components';
import React, { Component } from 'react';
import { get, find } from 'lodash';

import Layout from '../layout/Layout';

import { PageHero, ArticlesSection, SectionTitle, Title, Link } from '../common/components';
import { getInternalUrl } from '../utils';

interface Props {
  data: any;
  location: any;
  lang: string;
}
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  * {
    text-decoration-line: underline;
  }
`;
const GoBackButton = ({ title, link }) => (
  <ButtonContainer>
    <Link to={link}>
      <Title level={3}>{title}</Title>
    </Link>
  </ButtonContainer>
);

class Awards extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const awardsTitle = get(data, 'page.data.title', null);

    const body = get(data, 'page.data.body');
    const heroData = find(body, { __typename: 'PrismicAwardsBodyHero' });
    const articlesData = find(body, {
      __typename: 'PrismicAwardsBodyArticles',
    });
    const goBackText = get(articlesData, 'primary.goBackText', '');
    const goBackUrl = getInternalUrl(get(articlesData, 'primary.goBackUrl', ''), get(data, 'page.lang', 'en-us'));
    // console.log('>>> data', goBackText, goBackUrl);
    return (
      <Layout data={data} location={this.props.location}>
        {heroData && <PageHero data={heroData} />}
        {awardsTitle && <SectionTitle title={awardsTitle} />}
        {articlesData && (
          <ArticlesSection
            data={articlesData}
            displayFilters
            lang={get(data, 'page.lang', 'en-us')}
          />
        )}
        {goBackText && <GoBackButton title={goBackText} link={goBackUrl} />}
      </Layout>
    );
  }
}

export default Awards;

export const query = graphql`
  query AwardsQuery($slug: String!) {
    page: prismicAwards(id: { eq: $slug }) {
      ...Awards
    }
  }
`;
